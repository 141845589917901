// Import Bootstrap
@import "bootstrap/scss/bootstrap";

// Custom variables
$primary: #38A169;
$secondary: #48BB78;
$dark: #2D3748;
$light: #F7FAFC;
$body-bg: #FFFFFF;
$body-color: #4A5568;
$success: #48BB78;
$info: #4299E1;
$warning: #ECC94B;
$danger: #F56565;

// Font imports
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

// Custom styles
:root {
    scroll-behavior: smooth;
}

body {
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    color: $body-color;
    line-height: 1.7;
}

// Header styles
.header {
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    padding: 0.5rem 0;

    .navbar {
        padding: 0;
    }

    .logo {
        height: 60px;
        width: auto;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.05);
        }
    }

    .nav-link {
        color: $dark;
        font-weight: 500;
        padding: 1rem;
        transition: all 0.3s ease;
        position: relative;
        font-size: 0.95rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background: $primary;
            transition: width 0.3s ease;
        }

        &:hover {
            color: $primary;
            &:after {
                width: 100%;
            }
        }
    }
}

// Hero section
.hero {
    background: linear-gradient(rgba(45, 55, 72, 0.8), rgba(45, 55, 72, 0.8)), url('../assets/images/hero-bg.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: white;
    padding: 200px 0 100px;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 150px;
        background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
    }

    h1 {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
        line-height: 1.2;
    }

    .lead {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
        font-weight: 300;
    }

    .btn-primary {
        padding: 1rem 2.5rem;
        font-size: 1.1rem;
        border-radius: 50px;
        box-shadow: 0 4px 15px rgba(0,0,0,0.2);
        transition: all 0.3s ease;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        border: none;
        background: linear-gradient(135deg, $primary, $secondary);

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 6px 20px rgba(0,0,0,0.3);
            background: linear-gradient(135deg, darken($primary, 5%), darken($secondary, 5%));
        }
    }
}

// Section styles
.section-padding {
    padding: 120px 0;
}

.section-title {
    position: relative;
    margin-bottom: 4rem;

    h2 {
        font-size: 2.5rem;
        font-weight: 700;
        color: $dark;
        margin-bottom: 1rem;
        position: relative;
        display: inline-block;

        &:after {
            content: '';
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
            width: 80px;
            height: 3px;
            background: linear-gradient(to right, $primary, $secondary);
            border-radius: 3px;
        }
    }

    .section-subtitle {
        font-size: 1.1rem;
        color: $body-color;
        max-width: 600px;
        margin: 1rem auto 0;
        font-weight: 300;
    }
}

// Service cards
.service-card {
    background: white;
    padding: 3rem 2rem;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    height: 100%;
    border: 1px solid rgba(0,0,0,0.03);
    text-align: center;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 5px;
        background: linear-gradient(to right, $primary, $secondary);
        transform: scaleX(0);
        transition: transform 0.3s ease;
    }

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);

        &:before {
            transform: scaleX(1);
        }

        .service-icon {
            transform: scale(1.1);
            color: $secondary;
        }
    }

    .service-icon {
        font-size: 3.5rem;
        color: $primary;
        margin-bottom: 1.5rem;
        transition: all 0.3s ease;
    }

    h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: $dark;
        font-weight: 600;
    }

    p {
        color: $body-color;
        margin-bottom: 0;
        line-height: 1.7;
        font-size: 0.95rem;
    }
}

// About section
#sobre {
    position: relative;
    overflow: hidden;
    background: linear-gradient(135deg, rgba($light, 0.95), rgba(white, 0.98));
    padding: 80px 0;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle at 0% 0%, rgba($primary, 0.1) 0%, transparent 50%);
        z-index: 0;
    }

    .section-title {
        margin-bottom: 3rem;
        
        h2 {
            font-size: 2.5rem;
            background: linear-gradient(135deg, $primary, $secondary);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 1rem;

            &:after {
                height: 3px;
                width: 80px;
                background: linear-gradient(to right, $primary, $secondary);
                opacity: 0.8;
            }
        }

        .section-subtitle {
            font-size: 1.1rem;
            color: rgba($dark, 0.7);
        }
    }
}

.about-carousel {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0,0,0,0.15);
    transition: all 0.3s ease;

    .carousel-item {
        height: 400px;
        background-color: $dark;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.95;
        }
    }

    .carousel-caption {
        background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
        left: 0;
        right: 0;
        bottom: 0;
        padding: 2rem;
        text-align: left;

        h5 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.4);
        }

        p {
            font-size: 1rem;
            margin-bottom: 0;
            text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
        }
    }

    .carousel-indicators {
        margin-bottom: 1rem;

        button {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: rgba(255,255,255,0.6);
            border: none;
            margin: 0 4px;
            transition: all 0.3s ease;

            &.active {
                background-color: white;
                transform: scale(1.2);
            }
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 40px;
        height: 40px;
        background: rgba(255,255,255,0.25);
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.8;
    }
}

.about-content {
    padding-right: 2rem;
    position: relative;
    z-index: 1;

    .about-text {
        background: rgba(255, 255, 255, 0.9);
        padding: 2rem;
        border-radius: 15px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.08);
        margin-bottom: 1.5rem;

        p {
            color: rgba($dark, 0.8);
            line-height: 1.7;
            font-size: 1rem;
            margin-bottom: 1rem;
            
            &:last-child {
                margin-bottom: 0;
            }
        }

        .experience-note {
            font-size: 0.95rem;
            color: $primary;
            display: inline-block;
            padding: 0.5rem 1rem;
            background: rgba($primary, 0.1);
            border-radius: 8px;
            margin-top: 1rem;
        }
    }

    .features-list {
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        li {
            background: rgba(255,255,255,0.9);
            padding: 1rem;
            border-radius: 12px;
            display: flex;
            align-items: center;
            transition: all 0.3s ease;
            box-shadow: 0 5px 15px rgba(0,0,0,0.05);

            i {
                color: $primary;
                font-size: 1.2rem;
                margin-right: 0.8rem;
                transition: all 0.3s ease;
                background: rgba($primary, 0.1);
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
            }

            span {
                font-size: 0.95rem;
                color: $dark;
            }

            &:hover {
                transform: translateY(-3px);
                background: linear-gradient(135deg, rgba($primary, 0.08), rgba($secondary, 0.08));

                i {
                    background: $primary;
                    color: white;
                }
            }
        }
    }
}

// Contact section
.contact-section {
    background: linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url('../assets/images/contact-bg.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, rgba($primary, 0.08), rgba($secondary, 0.08));
        pointer-events: none;
    }

    .contact-info,
    .contact-form {
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(10px);
    }

    .row {
        height: 100%;
        > [class^="col-"] {
            display: flex;
            flex-direction: column;
        }
    }
}

.contact-info {
    padding: 3rem;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0,0,0,0.05);
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 5px;
        background: linear-gradient(to right, $primary, $secondary);
    }

    h3 {
        font-size: 1.75rem;
        margin-bottom: 2rem;
        color: $dark;
        font-weight: 600;
    }

    ul {
        flex: 1;
        margin-bottom: 2rem;
    }

    ul li {
        margin-bottom: 1.5rem;
        display: flex;
        align-items: flex-start;

        i {
            color: $primary;
            font-size: 1.5rem;
            margin-right: 1rem;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($primary, 0.1);
            border-radius: 12px;
            transition: all 0.3s ease;
        }

        span {
            font-size: 1.05rem;
        }

        a {
            color: $body-color;
            text-decoration: none;
            transition: color 0.3s ease;

            &:hover {
                color: $primary;
            }
        }

        &:hover i {
            background: $primary;
            color: white;
            transform: scale(1.1);
        }
    }

    .social-links {
        margin-top: auto;
        display: flex;
        gap: 1rem;

        .social-link {
            width: 45px;
            height: 45px;
            background: linear-gradient(135deg, $primary, $secondary);
            color: white;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            font-size: 1.2rem;

            &:hover {
                transform: translateY(-5px) rotate(5deg);
                box-shadow: 0 10px 20px rgba($primary, 0.3);
            }
        }
    }
}

.contact-form {
    padding: 3rem;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0,0,0,0.05);
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 5px;
        background: linear-gradient(to right, $primary, $secondary);
    }

    form {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .form-control {
        padding: 1rem 1.5rem;
        border-radius: 12px;
        border: 2px solid #E2E8F0;
        transition: all 0.3s ease;
        margin-bottom: 1.5rem;
        font-size: 1rem;

        &:focus {
            border-color: $primary;
            box-shadow: 0 0 0 3px rgba($primary, 0.1);
        }
    }

    textarea.form-control {
        flex: 1;
        min-height: 150px;
        resize: none;
    }

    button {
        width: 100%;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        transition: all 0.3s ease;
        background: linear-gradient(135deg, $primary, $secondary);
        border: none;
        margin-top: auto;

        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 10px 20px rgba($primary, 0.3);
        }
    }
}

// Footer
.footer {
    background: linear-gradient(135deg, #f8fafc 0%, #edf2f7 100%);
    color: $dark;
    padding: 6rem 0 0;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: linear-gradient(to right, $primary, $secondary);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: 
            radial-gradient(circle at 90% 90%, rgba($primary, 0.08), transparent 40%),
            radial-gradient(circle at 10% 10%, rgba($secondary, 0.08), transparent 40%),
            repeating-linear-gradient(45deg, rgba($primary, 0.03) 0px, rgba($primary, 0.03) 1px, transparent 1px, transparent 20px);
        pointer-events: none;
        opacity: 0.7;
    }

    h5 {
        color: $dark;
        font-size: 1.35rem;
        font-weight: 600;
        margin-bottom: 2rem;
        position: relative;
        display: inline-block;

        &:after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 50px;
            height: 3px;
            background: linear-gradient(to right, $primary, $secondary);
            border-radius: 2px;
        }
    }

    .footer-text {
        color: rgba($dark, 0.8);
        line-height: 1.8;
        margin-bottom: 2rem;
        font-size: 1rem;
    }

    .footer-list {
        list-style: none;
        padding: 0;
        margin: 0 0 2rem 0;

        li {
            color: rgba($dark, 0.8);
            margin-bottom: 1.2rem;
            display: flex;
            align-items: center;
            font-size: 1rem;
            transition: all 0.3s ease;

            &:hover {
                color: $primary;
                transform: translateX(5px);
            }

            i {
                color: $primary;
                margin-right: 12px;
                font-size: 1.1rem;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba($primary, 0.1);
                border-radius: 8px;
                transition: all 0.3s ease;
            }
        }

        li:last-child {
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
    }

    .social-links {
        display: flex;
        gap: 1.2rem;
        margin-top: 1rem;

        .social-link {
            width: 42px;
            height: 42px;
            background: linear-gradient(135deg, $primary, $secondary);
            color: white;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            font-size: 1.2rem;

            &:hover {
                transform: translateY(-5px) rotate(8deg);
                box-shadow: 0 8px 20px rgba($primary, 0.25);
            }
        }
    }

    .btn-outline-light {
        background: transparent;
        color: $primary;
        border: 2px solid rgba($primary, 0.2);
        border-radius: 10px;
        padding: 0.7rem 1.2rem;
        font-size: 0.95rem;
        font-weight: 500;
        transition: all 0.3s ease;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        margin-top: 0.5rem;
        position: relative;
        overflow: hidden;
        
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, rgba($primary, 0.1), rgba($secondary, 0.1));
            opacity: 0;
            transition: opacity 0.3s ease;
        }
        
        i {
            font-size: 1rem;
            color: $primary;
            transition: all 0.3s ease;
        }

        &:hover {
            border-color: $primary;
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba($primary, 0.15);

            &:before {
                opacity: 1;
            }

            i {
                transform: translateX(3px);
            }
        }

        &:active {
            transform: translateY(0);
            box-shadow: 0 2px 8px rgba($primary, 0.1);
        }
    }

    .footer-bottom {
        margin-top: 5rem;
        padding: 2rem 0;
        border-top: 1px solid rgba($dark, 0.1);
        position: relative;
        background: rgba($primary, 0.03);

        p {
            color: rgba($dark, 0.7);
            font-size: 0.95rem;
            margin: 0;
        }

        .footer-logo {
            height: 45px;
            width: auto;
            transition: all 0.3s ease;

            &:hover {
                transform: translateY(-3px);
            }
        }
    }

    [class^="col-"] {
        margin-bottom: 2.5rem;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 768px) {
    .footer {
        [class^="col-"] {
            margin-bottom: 0;
        }
    }
}

// Animations
[data-aos] {
    opacity: 0;
    transition-duration: 1s;
    transition-property: opacity, transform;
}

[data-aos].aos-animate {
    opacity: 1;
}

// Responsive adjustments
@media (max-width: 768px) {
    .hero {
        padding: 150px 0 80px;
        
        h1 {
            font-size: 2.5rem;
        }
        
        .lead {
            font-size: 1.2rem;
        }
    }

    .section-padding {
        padding: 80px 0;
    }

    .section-title h2 {
        font-size: 2rem;
    }

    .service-card {
        padding: 2rem;
    }

    #sobre {
        padding: 60px 0;
    }

    .about-carousel {
        margin-bottom: 2rem;

        .carousel-item {
            height: 300px;
        }
    }

    .about-content {
        padding-right: 0;

        .about-text {
            padding: 1.5rem;
        }

        .features-list {
            grid-template-columns: 1fr;
        }
    }

    .contact-info,
    .contact-form {
        padding: 2rem;
    }
}

#servicos {
    background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url('../assets/images/service-bg.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, rgba($primary, 0.1), rgba($secondary, 0.1));
        pointer-events: none;
    }

    .service-card {
        backdrop-filter: blur(5px);
        background: rgba(255, 255, 255, 0.9);
    }
} 